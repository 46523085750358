define("discourse/plugins/discourse-bcc/discourse/templates/connectors/composer-fields/bcc-checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <BccCheckbox
    @creatingPrivateMessage={{this.model.creatingPrivateMessage}}
    @targetRecipients={{this.model.targetRecipients}}
    @checked={{this.model.use_bcc}}
    @targetGroups={{this.model.hasTargetGroups}}
  />
  */
  {
    "id": "pwkTwmVp",
    "block": "[[[8,[39,0],null,[[\"@creatingPrivateMessage\",\"@targetRecipients\",\"@checked\",\"@targetGroups\"],[[30,0,[\"model\",\"creatingPrivateMessage\"]],[30,0,[\"model\",\"targetRecipients\"]],[30,0,[\"model\",\"use_bcc\"]],[30,0,[\"model\",\"hasTargetGroups\"]]]],null]],[],false,[\"bcc-checkbox\"]]",
    "moduleName": "discourse/plugins/discourse-bcc/discourse/templates/connectors/composer-fields/bcc-checkbox.hbs",
    "isStrictMode": false
  });
});